﻿$bottomCornersBorderRadius: 0 0 0.3125rem 0.3125rem;
$defaultBorder: 1px solid #e6e7e8;
$topCornersBorderRadius: 0.3125rem 0.3125rem 0 0;

// Search page banner
.featured-search-banner {
    background-color: #00c805;
    background-image: url(/globalassets/home/us/common/abstract-background-tertiary-green.png);
    background-position: 100% 50%;
    background-repeat: no-repeat;
    background-size: auto 100%;
    color: #fff;
    padding: 1.5rem;
}
.search-results__header {
    padding-bottom: .5rem;
}
.search-results__list {
    margin-bottom: 2.9rem;
}
.search-results {
    &__header {

        &:after {
            border-bottom: unset !important;
        }
    }
}

.page-class-search {
    #ui-id-2 {
        display: none !important;
    }

    .search-results__form {
        form-control {
            border: 1px solid $defaultBorder;
        }

        .search-auto-comp {
            width: 84%;
        }
    }

    .search__container {
        .search-auto-comp {
            position: absolute;
            z-index: 999;
        }

        #search-results-input {
            border: $defaultBorder;
        }
    }

    .site-header__search {
        .search-auto-comp {
            position: relative;
            width: 100%;
        }
    }
}
.search__container {

    .search__input-top-curved {
        border-radius: $topCornersBorderRadius;
    }

    #search__page_inputs {
        width: 100%;
        display: inline-flex;
    }

    .search-auto-comp {
        .auto-comp-list {

            li.ui-menu-item:last-child {
                border-radius: $bottomCornersBorderRadius;
            }

            padding: 0px;
            position: relative;
            z-index: 9999;

            .auto-comp-item {
                cursor: pointer;
                text-decoration: none;
                background-color: #fff;
                padding: 0px;

                a {
                    text-decoration: none;
                    padding: 16px;
                    display: block;

                    div {
                        color: #323232;

                        span {
                            font-weight: 700;
                            color: #001e6d;
                        }
                    }
                }

                &:hover {
                    text-decoration: none;
                    background-color: #17468f;

                    div {
                        color: #fff;

                        span {
                            color: #808080;
                        }
                    }
                }
            }
        }
    }
}