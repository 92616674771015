.expandable-nav {
    @media print {
        display: none !important;
    }

    position: fixed;
    height: 100vh;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    z-index:2;
    visibility: hidden;
    
    .popover {
        &.show {
            margin-top: 0;
        }

        .popover-body {
            min-width: 0;

            .share-widget__menu {
                display: block;
                transform: translateY(-50%);
                margin: 0;
                min-width: 200px;
                border: 2px solid #CCCCCC;

                .svg-inline--fa {
                    &.fa-w-16.fa-lg.icon {
                        width: 20px !important;
                        height: 20px !important;
                        margin: 0 20px 0 8px !important;
                    }
                }

                .share-menu-header .share-widget__action {
                    justify-content: center;
                    border-bottom: 1px solid #CCCCCC;
                    height: 50px;

                    &:focus,
                    &:hover {
                        background-color: unset;
                        color: unset;
                    }
                }
            }

            .share-widget__action:focus,
            .share-widget__action:hover {
                svg {
                    path {
                        stroke: white;
                    }
                }
            }
        }

        .arrow {
            display: block;

            &:after {
                right: 3px;
                z-index: 1200;
                border-right: 1px solid transparent;
                border-width: 1rem 0 1rem 1.5rem;
            }
        }
    }

    a:hover {
        text-decoration: none !important;
    }

    //Rounded corners for the first and last button.
        .expandable-nav__blocks > :first-child {
            border-top-left-radius: 5px;
        }

        .expandable-nav__blocks > :last-child, .expandable-nav__blocks > :last-child button {
            border-bottom-left-radius: 5px;
        }

        &.not-expanded {
            .expandable-nav__blocks > :first-child, .expandable-nav__blocks > :first-child button {
                border-bottom-left-radius: 5px;
            }
        }
}

.expandable-nav__blocks {
    display: flex;
    flex-direction: column;
    transition: top 0.3s ease-out;
    top: calc(100vh);
    position: relative;
    justify-content: start;
    align-self: flex-end;
    margin-bottom: 70px;

    & .can-be-hidden {
        &:hover {
            background-color: #148216;
        }
    }
    visibility: visible;
}

.expandable-nav.visible .expandable-nav__blocks {
    position: relative;
    background: transparent;
    top: 0;
}

.expandable-nav__block {
    width: 70px;
    opacity: 0.95;
    margin-bottom: 2px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    transition: transform, min-height 0.25s ease-in;
    min-height: 0px;
    height: 0px;
    overflow: hidden;
    opacity: 0.95;
    background: #4D4D4F;
    cursor: pointer;

    &.active {
        background: #148216;
    }

    &:hover, &.opened {
        background: #323232;
    }

    &.show {
        min-height: 70px;
        transform: scaleY(1);
    }

    .expandable-nav__block-icon {
        height: 25px;
        width: 25px;
        display: block;
        width: 25px;
        margin: 0 20px 5px;
    }

    .expandable-nav__text {
        line-height: 15px;
        font-size: 0.625rem;

        &:hover {
            text-decoration: none;
        }
    }
    //low resolution laptop screens
    @media (min-width: 767px) and (max-width: 1280px) and (max-height: 750px) and (orientation: landscape) {
        &.show {
            min-height: 60px;
        }

        max-width: 60px;
        overflow: hidden;
        opacity: 0.8;

        .expandable-nav__text {
            font-size: 0.5rem;
        }

        .expandable-nav__block-icon {
            height: 30px;
            width: 30px;
            display: block;
            margin: 0;
        }
    }
    //mobile only
    @media (max-width: 767px), (max-height: 450px) and (orientation: landscape) {
        &:nth-child(1) {
            order: 10;
        }

        &.show {
            min-height: 45px;
        }

        max-width: 45px;
        overflow: hidden;

        .expandable-nav__text {
            display: none;
        }

        .expandable-nav__block-icon {
            height: 25px;
            width: 25px;
            display: block;
            margin: 0;
        }
    }
}

@media (max-width: 767px), (max-height: 450px) and (orientation: landscape) {
    .expandable-nav {
        align-items: end;
        visibility: hidden;

        &__blocks {
            margin-bottom: 45px;
            visibility: initial;
            justify-content: flex-end;
        }

        // rounded corners
        .expandable-nav__blocks > :first-child {
            border-top-left-radius: 0px;
        }

        .expandable-nav__blocks > :last-child, .expandable-nav__blocks > :last-child button {
            border-bottom-left-radius: 0px;
        }

        &.not-expanded {
            .expandable-nav__blocks > :first-child, .expandable-nav__blocks > :first-child button {
                border-bottom-left-radius: 5px;
                border-top-left-radius: 5px;
            }
        }

        &.visible .expandable-nav__blocks {

            & > :first-child {
                border-bottom-left-radius: 5px;
            }

            & > :nth-child(2) {
                border-top-left-radius: 5px;
            }
        }
    }
}

/*Styles for the scroll to top button*/
.expandable-nav {

    #back-to-top-wrapper .scroll-to-top.back-to-top.btn {
        position: fixed;
        //bottom: 0;
        right: 0;
        height: 70px;
        width: 70px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;
        background-color: #FFFFFF;
        opacity: 0.8;
        letter-spacing: 0;
        padding: 0 5px;

        > svg {
            height: 30px;
            width: 80px;
        }

        &:focus, &:hover {
            background-color: #d8e0e8;
            border-color: #d0dae3;
            color: #323232;
        }

        > span.text-util-sm {
            letter-spacing: 0;
        }
    }

    @media (max-width: 767px) {
        #back-to-top-wrapper .scroll-to-top.back-to-top.btn {
            height: 45px;
            width: 45px;
            border: 0;
            justify-content: center;

            > svg {
                height: 25px;
                width: 25px;
            }

            > .text-util-sm {
                display: none;
            }
        }
        // rounded corners
        .expandable-nav__blocks > :first-child {
            border-top-left-radius: 0px;
        }

        .expandable-nav__blocks > :last-child, .expandable-nav__blocks > :last-child button {
            border-bottom-left-radius: 0px;
        }

        &.not-expanded {
            .expandable-nav__blocks > :first-child, .expandable-nav__blocks > :first-child button {
                border-bottom-left-radius: 5px;
                border-top-left-radius: 5px;
            }
        }

        &.visible .expandable-nav__blocks {

            & > :first-child {
                border-bottom-left-radius: 5px;
            }

            & > :nth-child(2) {
                border-top-left-radius: 5px;
            }
        }
    }

    @media (max-height: 450px) and (orientation: landscape) {
        #back-to-top-wrapper .scroll-to-top.back-to-top.btn {
            height: 45px;
            width: 45px;
        }

        #back-to-top-wrapper > button > span {
            visibility: hidden;
            display: none;
        }
    }
}

/* styles for the modal open backdrop*/
.modal-backdrop.show {
    opacity: .95;
}