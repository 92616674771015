@import '../style/common';

.download-card {
  background-color: $body-bg;
  display: block;
  padding: space(4);
  position: relative;
  z-index: 0;

  @include plain-hover-focus {
    color: $body-color;
    text-decoration: none;
  }

  &:before {
    background-image: url('./assets/images/abstract-background.png');
    background-position: right center;
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    display: block;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    transition: opacity $trans-duration $trans-easing;
  }

  @include hover-focus {
    z-index: 1;

    &:before {
      opacity: 1;
    }
  }
}
