
.alert-bar {
    background-image: url(/globalassets/home/us/common/share-form-bg.svg);
    background-position: 80% 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.preview-card:before {
    background-image: url(/globalassets/home/corp/common/abstract-background.png);
    background-position: 70% 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.insight-card:before {
    background-image: url(/globalassets/home/corp/common/abstract-background.png);
}

.download-card:before {
    background-image: url(/globalassets/home/corp/common/abstract-background.png);
}

.interactive-image:before {
    background-image: url(/globalassets/home/corp/common/abstract-background.png);
}

.preview-card:before {
    background-image: url(/globalassets/home/corp/common/abstract-background.png);
}

.preview-card:before, .search-results-card:before, .interactive-image:before {
    background-image: url(/globalassets/home/corp/common/abstract-background.png);
    background-position: 70% 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.site-header__submenu {
    background-image: url(/globalassets/home/global/submenu-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right center;
}

.callout-block .card {
    background-image: url(/globalassets/home/corp/common/abstract-background-light.svg);
    background-position: 46% 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.share-with-a-friend-form__content {
    background-image: url(/globalassets/home/us/common/form-bg.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

.attachment-block__content-bg-inner:after {
    background-color: #f0f3f6;
    background-image: url(/globalassets/home/us/common/share-form-bg.svg);
    background-position: 64% 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.register-form__content {
    background-color: #f0f3f6;
    background-image: url(/globalassets/home/us/common/form-bg.svg);
    background-position: top right;
    background-repeat: no-repeat;
    background-size: cover;
}

.utility-menu__body {
    background-image: url(/globalassets/home/us/common/utility-bg.svg);
    background-size: 100vh;
}

.thought-leaders-card:before {
    background: url(/globalassets/home/us/common/abstract-background.svg) no-repeat center;
    background-size: cover;
}

@media all and (-ms-high-contrast:none) {
    div.callout-block .card {
        background-image: url(/globalassets/home/corp/common/abstract-background-light.png);
        background-position: 46% 50%;
        background-repeat: no-repeat;
        background-size: cover;
    }
}


/***Font overrides to prevent 404s*/
@font-face {
    font-family: "slick";
    src: url("/static/fonts/slick/slick.eot");
    src: url("/static/fonts/slick/slick.eot?#iefix") format("embedded-opentype"), url("/static/fonts/slick/slick.woff") format("woff"), url("/static/fonts/slick/slick.ttf") format("truetype"), url("/static/fonts/slick/slick.svg#slick") format("svg");
    font-weight: normal;
    font-style: normal;
}

.slick-loading .slick-list {
    background: #fff url("/static/fonts/slick/ajax-loader.gif") center center no-repeat;
}

.subscription-center-page .your-information__header, .subscription-center-page .checkbox-group__header, .manage-subscription .checkbox-group__header {
    background-image: url(/globalassets/home/corp/common/abstract-background-tertiary.svg);
}