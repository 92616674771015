
// See existing SASS 4 utils before adding anything here: https://getbootstrap.com/docs/4.0/utilities/

// ----- font families -----

.font-family-sans-serif {
  font-family: $font-family-sans-serif;
}

// ----- font sizes -----

@each $name, $size in $font-sizes {
  .font-size-#{$name} {
    font-size: $size;
  }
}

// ----- font weights -----

.font-weight-extra-bold {
  font-weight: $font-weight-extra-bold;
}

.font-weight-semi-bold {
  font-weight: $font-weight-semi-bold;
}

// ----- colors -----

.bg-black {
  background-color: $black;
}

.text-black {
  color: $black;
}

