@import '../style/common';

.page-header {
  $page-header-switch-breakpoint: lg;

  border-bottom: $border-style;
  padding-bottom: space(5);

  &__container {
    @include make-row;
  }

  &__main {
    @include make-col-ready;
    @include media-breakpoint-up($page-header-switch-breakpoint) {
      @include make-col(8);
    }
  }

  &__eyebrow {
    color: $tertiary;
  }

  &__heading {
    margin: 0;
  }

  &__body {
    margin-top: space(3);
  }

  &__cta-container {
    margin-top: space(4);
  }
}
