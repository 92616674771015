@import '../style/common';

.fund-explorer-screener {
  background: url('#{$images-path}background_etf_funds.svg');
  background-size: cover;
  padding-bottom: space(5);
  padding-top: 3.75rem;
  margin-bottom: space(2);

  &__title {
    text-transform: uppercase;
    font-size: $font-size-xxs;
    font-weight: $font-weight-semi-bold;
    line-height: $h4-line-height;
    letter-spacing: $text-util-sm-letter-spacing;
  }

  &__heading {
    margin-bottom: 2rem;
  }

  &__dropdown p,
  &__heading {
    color: $white;
  }

  &__dropdown {

    .form-control {
      position: relative;
    }

    &:last-child {
      border-left: $border-width solid $gray-4;
      height: 5.5rem;
      left: 0;
    }

    .dropdown {
      &__item,
      &__button {
        font-weight: $font-weight-bold;
        text-transform: capitalize;
      }

      &__button {
        padding: 0.75rem 2.5rem 0.75rem space(3);

        &:focus {
          background-color: $white;
        }
      }

      &__label,
      &__item {
        font-size: $h3-font-size;
        line-height: $h4-line-height;
        letter-spacing: normal;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &__dropdown {
      padding-right: 0;

      &:nth-last-child(2) {
        padding-right: space(3);
      }

      &:last-child {
        padding-right: space(3);
      }
    }
    max-height: 15.875rem;
  }

  @include media-breakpoint-down(md) {
    &__dropdown {
      margin-bottom: space(3);
    }

    &__title {
      margin-bottom: 0.75rem;
    }
  }

  @include media-breakpoint-down(md) {
    &__dropdown {
      &:last-child {
        border-left: 0;
      }
    }
  }
}
