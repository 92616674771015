$color_1: #ffffff;
$font-family_1: "Open Sans", "Helvetica", sans-serif;

.corp-related-media-jumbotron {
    .related-insights {
        &_hero-card {
            padding: 2.5rem 2.5rem;

            @media screen and (max-width: 430px) {
                .insight-card__title {
                    font-size: 2rem;
                }

                .insight-card__text {
                    font-size: 1rem;
                }
            }

            .insight-card__title {
                color: $color_1;
                font-size: 2.5rem;
            }

            .insight-card__text {
                color: $color_1;
                font-size: 1.1rem;
                font-family: $font-family_1;
                font-weight: 500;
                text-transform: none;
            }

            .btn {
                display: table;
                margin: 0 auto;
            }

            &:hover {
                &:before {
                    opacity: 0;
                }
            }
        }

        &_centered-card {
            .btn {
                display: table;
                margin: 0 auto;
            }

            .insight-card__title {
                text-align: center;
                line-height: 1.4;
            }

            .insight-card__text {
                text-align: center;
                text-transform: none;
                font-weight: 500;
                letter-spacing: 0.02rem;
                font-size: 0.73rem;
            }
        }

        &_centered-card.hasBtn {
            .btn-wrapper {
                position: absolute;
                bottom: 1.5rem;
                left: 0;
                z-index: 2;
            }

            position: relative;
            padding-bottom: 110px;
        }

        &_hero-card.hasBtn {
            .btn-wrapper {
                position: absolute;
                bottom: 1.5rem;
                left: 0;
                z-index: 2;
            }

            position: relative;
            padding-bottom: 110px;
        }

        &__list-item {
            &.page-not-found-twitter-card {
                .insight-card {
                    background-size: cover;
                }
            }
        }
    }
}