/****
    SECTION 1 - Generic classes and general overrides
****/

@media all and (min-width:992px) {
    .flex-no-height {
        height: 0; /* removes excess space on ordered pages when the sidebar is too large */
    }
}

.slick-dots li button:before {
    font-size: 1rem !important;
}

.video-playlist__preview-image-overlay {
    opacity: 0.9;
    background-image: linear-gradient(180deg, rgba(238,238,238,0.00) 0%, #000000 91%);
}

/* reduce the width of the hero container text container so any text is within the overlay images. */
@media all and (min-width: 549px) {
    .hero__content-container {
        max-width: 85%;
    }
}

@media all and (min-width: 992px) {
    .hero__content-container {
        max-width: 50%;
    }
}

@media (min-width: 992px) {
    .site-header .nav-item:hover .site-header__submenu, .site-header .nav-item:focus .site-header__submenu {
        display: block;
    }
}

.site-header .nav-item:hover > .nav-link:after, .site-header .nav-item.open > .nav-link:after {
    background-color: #17468f;
}

.site-header.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1020;
}

header.site-header.no-drop-shadow {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

/****
     SECTION 13 - Search page
*/

@media (min-width: 576px) {
    .search__container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .search__container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .search__container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .search__container {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {
    .search__container {
        max-width: 1320px;
    }
}

@media (min-width: 992px) {
    .search__container {
        padding-right: 3rem;
        padding-left: 3rem;
    }
}

/*py-bottom-0 class added*/
.py-bottom-0, div.py-bottom-0 {
    padding-bottom: 0 !important;
}

.py-top-0, div.py-top-0 {
    padding-top: 0 !important;
}



/****
    SECTION 7 - Richtext block images
****/
.rich-text-block img {
    max-width: 100%;
    height: auto;
}


/** Subscriptions Checkbox */

.subscription-center-page .your-information__header, .subscription-center-page .checkbox-group__header, .manage-subscriptions-page .checkbox-group__header {
    background-image: url(/globalassets/home/corp/common/abstract-background-tertiary.svg);
}

.custom-checkbox .custom-control-label:before, .custom-control-input:checked ~ .custom-control-label:before {
    border-color: #323232
}

.site-header {
    .navbar-toggler {
        svg {
            pointer-events: none;
        }
    }
}
