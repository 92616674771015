.featured-podcast {
  @media (hover: hover) {
      .share-icon-wrapper:hover .tooltip {
          opacity: 1;
          visibility: visible;
      }
  }
}

.podcast-list {
  .section-header {
      border-color: #e6e7e8;
      flex-wrap: wrap;

      &__dropdown:nth-child(2) {
          margin-left: 0;
      }

      @include media-breakpoint-down(sm) {
          margin: 0;
          padding: 0;
      }
  }

  .insights-grid {
      flex-wrap: wrap;
      column-gap: 30px;
      max-width: 100%;
  }

  .section-header__dropdown {
      @include media-breakpoint-down(sm) {
          width: auto;
      }
  }

  .dropdown__button {
      display: flex;

      svg {
          position: relative;    
      }
  }
  
  .text-util-md-title {
      font-size: 30px;
      margin: 12px 0 0;
      line-height: 1;
      letter-spacing: 0;
      text-transform: capitalize;
      width: 100%;
  }

  .text-util-md-title,
  .podcast-card {
      padding-left: 0;
      padding-right: 0;
  }

  @media (hover: hover) {
      .share-icon-wrapper:hover .tooltip {
          opacity: 1;
          visibility: visible;
      }
  }
  
  @media (hover: none) {
      .share-icon-wrapper:active .tooltip {
          opacity: 1;
          visibility: visible;
      }
  }

  .grid-card {
      display: flex;
      flex-direction: row;
      column-gap: 34px;
      border-bottom: 1px solid #e6e7e8;
      padding: 30px 0;

      &__image-container {
          margin-bottom: 0 !important;
      }

      &__image-icon {
          right: 20px;
          bottom: 15px;    
      }

      .grid-card_header {
          margin-bottom: 8px;
      }
      
      .grid-card_author {
          margin-bottom: 16px;
      }
      
      .grid-card_read-time {
          display: flex;
          align-items: center;
          gap: 8px;
      }
  }

  .ve-pagination {
      position: relative;
      z-index: 1;

      & .page-input {
          width: 48px;
          height: 48px;
          text-align: center;
          -moz-appearance: textfield;

          &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
          }
      }

      & button.btn-pagination:hover rect {
          fill: #001E6D;
      }

      & .pagination-container {
          padding: 0 15px;
      }
  }
}

.podcast-button-wrapper {
    position: relative;
}

.podcast-social-tooltip {
    position: absolute;
    bottom: calc(100% + 10px);
    right: -6px;
    background: white;
    border-radius: 12px;
    padding: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s, visibility 0.2s;
    z-index: 1000;
    min-width: 150px;

    @include media-breakpoint-down(sm) {
        right: -30%;
    }

    &::before {
        content: '';
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        width: 16px;
        height: 16px;
        background: white;
    }

    &.visible {
        opacity: 1;
        visibility: visible;
    }
}

.podcast-social-tooltip {
    bottom: calc(100% + 15px);
    box-shadow: none;
    border: 1px solid #979797;
    border-radius: 0;
    cursor: default;

    &::before {
        border-bottom: 1px solid #979797;
        border-right: 1px solid;
        right: 0;
        left: auto;
        bottom: -9px;
    }

    .tooltip-title {
        font-size: 18px;
        font-weight: 700;
    }

    .social-buttons {
        display: flex;
        column-gap: 20px;

        a {
            flex-shrink: 0;
            height: 50px;
            width: 50px;
            border: 1px solid #979797;
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                height: 30px;
                width: 30px;
            }
        }
    }
}

.podcast-links {
    .social-buttons-wrapper {
        margin-top: 20px;
        column-gap: 30px;

        @include media-breakpoint-down(sm) {
            margin-top: 16px;
            column-gap: 24px;
            justify-content: flex-start;
        }
    }
}

.podcast-links-item {
    height: 40px;
    display: flex;
    column-gap: 5px;
    align-items: center;
    border: 1px solid $gray-5;
    border-radius: 20px;
    font-size: 0.933rem;
    padding: 0 10px;
    color: $gray-5;
    
    img {
        max-height: 28px;
    }

    @include media-breakpoint-down(sm) {
        height: 50px;
        width: 50px;
        justify-content: center;

        img {
            height: 36px;
            width: 40px;
            max-height: 100%;
        }
    }

    span {
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}

.podcast-card {
    width: 100%;
    max-width: 100%;
    flex: auto;
    margin-bottom: 30px;

    &__header {
        margin-bottom: 10px;
    }

    &__image {
        width: 100%;
        max-width: 405px;
        aspect-ratio: 405/228;
        cursor: pointer;

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    &__content {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    &__date {
        font-size: 11px;
        font-weight: 600;
        text-transform: uppercase;

        p {
            margin-bottom: 20px;
        }
    }

    &__header h3 {
        cursor: pointer;
    }

    &__tags {
        display: flex;
        column-gap: 10px;
        flex-wrap: wrap;
        font-size: .75rem;
        font-weight: 800;
        line-height: .75rem;
        margin: 10px 0 20px;
        row-gap: 5px;

        @media screen and (max-width: 360px) {
            column-gap: 10px;
        }

        .tag {
            text-transform: uppercase;
            text-decoration: none;
            color: $gray-5;
            cursor: pointer;
            position: relative;
            padding: 0;
            height: 12px;

            &:not(:last-child) {
                padding-right: 10px;

                &:after {
                    background-color: $gray-5;
                    content: "";
                    height: 100%;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 1px;
                }
            }

            &:hover {
                color: $blue;
                text-decoration: underline;
            }

            @include media-breakpoint-down(sm) {
                margin-top: 10px;
            }
        }
    }

    &__listen-time {
        font-size: 15px;
        font-weight: 600;
        color: #001F4E;
        text-transform: uppercase;
        flex: 1;

        p,
        a {
            display: flex;
            align-items: center;
            column-gap: 20px;
            margin-bottom: 0;
            cursor: pointer;

            span {
                color: #001F4E;

                @include media-breakpoint-down(sm) {
                    display: none;

                    @media print {
                        display: block;
                    }
                }
            }
        }
    }

    &__description p {
        margin-bottom: 10px;
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        position: relative;
        flex: 1;
    }

    &__icons {
        display: flex;
        column-gap: 20px;
        align-items: end;
        position: relative;

        @include media-breakpoint-down(sm) {
            right: 0;
            top: -33px;
            position: absolute;
        }

        .share-icon {
            position: relative;
        }

        > div {
            cursor: pointer;
        }

        .tooltip {
            position: absolute;
            background: rgba(0, 0, 0, 0.7);
            color: white;
            padding: 4px 8px;
            border-radius: 4px;
            font-size: 12px;
            white-space: nowrap;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.2s, visibility 0.2s;
            bottom: calc(100% + 5px);
            left: 50%;
            transform: translateX(-50%);
            margin-top: 5px;
        }
    }

    &.featured-podcast {
        margin-top: 40px;
        padding-bottom: 26px;

        @include media-breakpoint-down(lg) {
            border-bottom: 1px solid $gray-2;
        }

        .grid-card {
            flex-direction: row;
            column-gap: 34px;

            &__category {
                font-size: 11px;
                font-weight: 600;
                line-height: 1;
                margin-bottom: 5px;
                text-transform: uppercase;
            }

            &__image-container {
                margin-bottom: 0 !important;
            }
        }

        .featured-podcast__heading {
            text-transform: uppercase;
            padding: 8px 0;
            font-size: 13px;
            font-weight: 600;
            border-bottom: 1px solid #939598;
            margin-bottom: 24px;

            p {
                margin-bottom: 0;
            }
        }

        .podcast-card__image {
            aspect-ratio: 519 / 289;
            max-width: 519px;
        }
    }
}

.podcast-page-hero {
    margin-bottom: 30px;

    .text-hero__divider {
        display: none;
    }
}

.text-hero__body--with-image {
    display: flex;
    justify-content: space-between;
    column-gap: 100px;

    @include media-breakpoint-down(md) {
        column-gap: 50px;
    }

    @include media-breakpoint-down(xs) {
        flex-direction: column;
    }

    img {
        max-width: 120px;
        width: 100%;
        object-fit: cover;
        aspect-ratio: 1;
        border-radius: 100%;

        @include media-breakpoint-down(md) {
            max-width: 140px;
        }

        @include media-breakpoint-down(xs) {
            margin: 20px 0;
        }
    }
}

.podcast-player {
    position: fixed;
    bottom: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    max-width: 515px;
    transform: translateX(-50%);
    left: 50%;
    border-top: 1px solid $gray-3;
    border-left: 1px solid $gray-3;
    border-right: 1px solid $gray-3;
    max-height: 91px;
    width: calc(100% - 60px);

    @media print {
        display: none;
    }

    @media screen and (min-width: 576px) {
        max-width: 485px;
    }

    @media screen and (min-width: 768px) {
        max-width: 655px;
    }

    @media screen and (min-width: 992px) {
        max-width: 895px;
    }

    &.show {
        opacity: 1;
        visibility: visible;
        z-index: 1;
    }
    
    .close {
        position: absolute;
        right: -2px;
        top: -3px;
        cursor: pointer;

        svg {
            font-size: 14px;
            width: 20px;
            height: 20px;
            fill: $gray-3;
        }
    }
}

.central-tooltip {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 12px 24px;
    border-radius: 4px;
    font-size: 14px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s, visibility 0.2s;
    z-index: 1000;
}

.central-tooltip.visible {
    opacity: 1;
    visibility: visible;
}

.insights-full-width > .row {
    flex: 1;
}