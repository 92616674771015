@import '../style/common';

.interactive-image {
  position: relative;
  background-color: $body-bg;
  padding: space(3);
  transition: background-color $trans-duration $trans-easing;
  z-index: 0;
  border: $border-width solid $border-color;

  &:before {
    background-image: url('./assets/images/abstract-background.png');
    background-position: 70% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    transition: opacity $trans-duration $trans-easing;
  }

  &__inner {
    position: relative;
  }

  &__image {
    opacity: 1;
    transition: opacity $trans-duration $trans-easing;
  }

  &__hover-image {
    opacity: 0;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity $trans-duration $trans-easing;
  }

  @include hover {
    background-color: $light;

    &:before {
      opacity: 1;
    }

    .interactive-image__image {
      opacity: 0;
    }

    .interactive-image__hover-image {
      opacity: 1;
    }
  }
}
