

.image-tooltip {
  &__inner {
    background-color: $body-bg;
    box-shadow: $box-shadow-light;
    color: $body-color;
    max-width: 100%;
    padding: space(4);
  }

  &__image {
    @include img-fluid;
  }
}
