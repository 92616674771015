@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local(''), url('/static/fonts/OpenSans/open-sans-v18-latin-regular.woff2') format('woff2'), url('/static/fonts/OpenSans/open-sans-v18-latin-regular.woff') format('woff');
}
/* open-sans-italic - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: local(''), url('/static/fonts/OpenSans/open-sans-v18-latin-italic.woff2') format('woff2'), url('/static/fonts/OpenSans/open-sans-v18-latin-italic.woff') format('woff');
}
/* open-sans-600 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local(''), url('/static/fonts/OpenSans/open-sans-v18-latin-600.woff2') format('woff2'), url('/static/fonts/OpenSans/open-sans-v18-latin-600.woff') format('woff');
}
/* open-sans-600italic - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    src: local(''), url('/static/fonts/OpenSans/open-sans-v18-latin-600italic.woff2') format('woff2'), url('/static/fonts/OpenSans/open-sans-v18-latin-600italic.woff') format('woff');
}
/* open-sans-700 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: local(''), url('/static/fonts/OpenSans/open-sans-v18-latin-700.woff2') format('woff2'), url('/static/fonts/OpenSans/open-sans-v18-latin-700.woff') format('woff');
}
/* open-sans-700italic - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    src: local(''), url('/static/fonts/OpenSans/open-sans-v18-latin-700italic.woff2') format('woff2'), url('/static/fonts/OpenSans/open-sans-v18-latin-700italic.woff') format('woff');
}
/* open-sans-800 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    src: local(''), url('/static/fonts/OpenSans/open-sans-v18-latin-800.woff2') format('woff2'), url('/static/fonts/OpenSans/open-sans-v18-latin-800.woff') format('woff');
}
/* open-sans-800italic - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 800;
    src: local(''), url('/static/fonts/OpenSans/open-sans-v18-latin-800italic.woff2') format('woff2'), url('/static/fonts/OpenSans/open-sans-v18-latin-800italic.woff') format('woff');
}
