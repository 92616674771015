﻿@import '../scss/style/common';

.utility-menu {
    &__icon {
        width: 80px;
        min-height: 80px;
        color: $white;
        text-transform: uppercase;
        font-weight: $font-weight-semi-bold;
        letter-spacing: 0.063rem;
        background-color: $primary;
        border-radius: 8px;
        position: absolute;
        justify-content: center;
        right: 1.5rem;
        z-index: 100;

        .character-icon {
            font-size: 24px;
            line-height: 1.5;
            letter-spacing: -0.5px;
            font-weight: 600;
            -webkit-text-stroke-width: 0.5px;
            -webkit-text-stroke-color: #17468F;
            position: relative;

            &:before {
                content: "";
                border-radius: 50%;
                border: 2px solid #fff;
                position: absolute;
                height: 35px;
                width: 35px;
                top: 2px;
                right: -7px;
            }
        }

        &-text {
            font-size: 11px;
            letter-spacing: 1px;
            font-weight: 500;
        }

        &-bar {
            color: $primary;
        }

        @include hover {
            text-decoration: none;
            background-color: #fff;
            /*color:$primary;*/
            border: 2px solid $primary;

            .character-icon {
                color: $primary;

                &:before {
                    border-color: $primary;
                }
            }

            .utility-menu__icon-bar {
                color: $white;
            }
        }

        &:focus-visible {
            color: $white;
            background-color: $primary;

            .utility-menu__icon-bar {
                color: $white;
            }
        }

        &.button-outline {
            @include link-focus-outline;
        }

        @include media-breakpoint-down(md) {
            position: relative;
            bottom: 0;
            right: 1rem;
        }
    }

    &--sticky {
        .utility-menu__icon {
            position: fixed;
            bottom: 50%;
            transform: translateY(50%);

            @include media-breakpoint-down(lg) {
                bottom: space(3);
                transform: none;
            }
        }
    }

    &__close {
        color: $primary;
        position: absolute;
        right: 2rem;

        @include media-breakpoint-down(xs) {
            right: space(2);
        }

        @media (max-width: 767px), (max-height: 450px) and (orientation: landscape) {
            top: 3rem;
        }

        &.close-icon {
            @include link-focus-outline;
        }
    }

    &__heading {
        line-height: $h4-line-height;
        padding-bottom: 0.75rem;
    }

    &__body {
        /*background-image: url('#{$images-path}utility-bg.svg');*/
        background-size: 100vh;
        padding: 2.5rem space(5);

        @include media-breakpoint-down(xs) {
            padding: space(4) space(3);
        }
    }

    &__dialog {
        max-width: 28rem;
        min-width: auto !important;
        width: 100%;
        margin: 0;
        right: 0;
        position: absolute;
        bottom: 0;
        top: 0;
        transform: translate3d(0%, 0, 0) !important; // stylelint-disable declaration-no-important

        &.modal-dialog {
            display: flex;
            align-items: flex-end;
        }
    }

    .dropdown__button-default:disabled {
        background-color: $gray-1;
        pointer-events: none;
    }

    &__button-wrapper {
        display: flex;
        flex-direction: column-reverse;
    }

    &__terms-condition {
        font-weight: $font-weight-normal;
        font-size: $h6-font-size;
        line-height: $h4-line-height;
        display: block;
        padding: space(2) 0 2rem;
    }

    &__cta-wrapper {
        &__cta-outline {
            @include link-focus-outline;
        }
    }

    &__schedule-link {
        @include link-focus-outline;
        border-radius: $border-radius-sm;
        background-color: $primary;
        color: $white;
        background-image: $secondary-linear-gradient;
        background-size: 100%;
        background-repeat: no-repeat;
        padding: 2rem;
        margin-top: 2rem;
        font-size: $h2-font-size;
        line-height: $h2-line-height;
        font-weight: $font-weight-bold;

        .icon {
            font-size: $h2-font-size;
        }

        &:hover {
            color: $white;
            text-decoration: none;
        }
    }

    .modal {
        overflow-y: overlay;
        z-index: 99999;
    }

    .accordion {
        border-top: none;
        border-bottom: $border-width solid $dark-blue;

        &__label {
            font-size: $h4-font-size;
        }

        &__content {
            padding: 0;
        }

        &__toggle {
            @include link-focus-outline;
            padding: space(3) space(5) 2rem 0;

            @include hover-focus {
                text-decoration: none;
            }
        }

        &__icon {
            width: 30px;
        }
    }

    &__disclaimer {
        @include make-text-util(xs);
        padding: space(1) 0 space(4);
    }
}



.utility-menu {

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        button.utility-menu__icon {
            /** IE11 only fix for Utility Menu */
            flex-direction: row !important;
            flex-wrap: wrap !important;
            justify-content: center;
        }

        button.utility-menu__close {
            top: 32px;
        }
    }

    &__content {
        &.modal-content {
            max-height: 100vh;
            overflow: auto;
        }
    }

    &__body {

        .accordion {
            //The markup here is designed for use on horizontial layouts, we have to break the columns in a vertical utility menu.
            &.manage-subscriptions-block {
                background-color: unset;
                background-image: none;
                /* Remove the border bottom for the manage subscriptions section only */
                border-bottom: 0px;

                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    background-color: rgba(0,0,0,0);
                }

                section.Form__Element > div {
                    padding-right: 0;
                    padding-left: 0;
                    margin-bottom: 1rem !important;
                }

                #new-subscription-access-success {

                    .col-lg-4 {
                        display: none;
                    }

                    .Form__Status__Message {
                        padding-right: 20px;

                        > .col-lg-6 {
                            min-width: 100%;
                            padding-right: 20px;
                        }

                        .font-size-xs {
                            letter-spacing: normal;
                        }
                    }
                }
            }

            &__content {

                form.pt-3 {
                    //remove extra padding in the utility menus only.
                    padding-top: 0 !important;
                }

                .already-subscribed {
                    display: none;
                }

                .Form__MainBody .subscription-access-email-container {
                    min-width: 100%;
                }

                .Form__MainBody .subscription-access-button-container {
                    min-width: 100%;
                }

                .Form__Element__ValidationError {
                    color: red;
                    text-transform: uppercase;
                    font-size: 0.8rem;
                    margin-top: 5px;
                    display: inline-block;
                }
            }

            &.new-subscription-block {
                .Form__Status .Form__Success__Message {
                    padding-top: 0;
                    margin-top: 0;
                }

                .one-click-form-thank-you {
                    display: block;
                }

                .Form__Status__Message.hide {
                    display: none !important
                }

                .subscribe-to {

                    &__title {
                        font-size: 0.6871875rem;
                        font-weight: 600;
                        letter-spacing: 0.0625rem;
                        text-transform: uppercase;
                        margin-bottom: 0.5 rem !important;
                    }

                    font-family: "Open Sans", "Helvetica", sans-serif;
                    font-weight: 700;
                    font-size: 1.0621875rem;
                    line-height: 1.3529;
                    margin-bottom: 1rem !important;

                    @media (min-width: 768px) {
                        font-size: 1.186875rem;
                        line-height: 1.1315;
                    }
                }

                form:not(.custom-select-form) {
                    .Form__Element {
                        margin-bottom: 0;
                    }
                }

                .FormSubmitButton {
                    width: 100%;
                }
            }

            .manage-subscriptions-link {
                .subscribe-block__content {
                    padding: 0;
                }

                .h3 {
                    display: none;
                }

                .Form__Element {
                    margin-bottom: 0;
                }

                .btn-block {
                    margin-bottom: 10px;
                }
            }
        }
    }
}

// mobile only styles
@media (max-width: 767px), (max-height: 450px) and (orientation: portrait), (orientation: landscape) {
    .utility-menu__body.modal-body .utility-menu__capability-insights .accordion__toggle {
        max-width: 90%;
    }
}