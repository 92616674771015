@import '../style/common';

.fund-explorer-table {
  $fund-explorer-table-switch-breakpoint: md;

  .nav.nav-tabs {
    display: none;
  }
  .nav.nav-tabs.show {
    display: block;
  }

  .tab-content > .tab-pane {
    display: none;
    visibility: hidden;
    height: 0;
  }

  .tab-content > .active {
    visibility: visible;
    height: auto;
    display: block;
  }

  .popover-height {
    min-height: 18rem;
  }

  .sort-thead {
    position: relative;

    &:hover .sort-thead-content {
      position: absolute;
      top: 100%;
      visibility: visible;
      display: block;
    }

    &-content {
      visibility: hidden;
      border-radius: 0;
      background-color: $white;
      color: $gray-5;
      min-width: 7.19rem;
      padding: 0.62rem;
      border: $border-width solid $gray-8;
      box-shadow: 0 2px space(1) 0 rgba($gray-5, 0.5);
      display: none;
      text-align: left;
      span {
        font-size: $font-size-xs;
        color: $gray-5;
        font-weight: $font-weight-bold;
        font-family: $font-family-base;
      }
    }
  }

  .form-check {
    text-align: left;

    &-input {
      font-size: $font-size-lg;
      line-height: 1;
      @include media-breakpoint-down(md) {
        margin-top: 0.3rem;
      }
    }

    &-label {
      font-size: $font-size-xxs;
      line-height: 1;
      font-weight: $font-weight-semi-bold;
      font-family: $font-family-base;
      padding-left: space(1);
      color: $gray-5;
      letter-spacing: normal;
      text-transform: capitalize;
    }

    input[type='radio']:after {
      width: space(3);
      height: space(3);
      border-radius: 50%;
      position: relative;
      background-color: $white;
      content: '';
      display: inline-block;
      visibility: visible;
      border: 2px solid $gray-7;
      transform: translate(-1px, -1px);
    }

    input[type='radio']:checked:after {
      width: space(3);
      height: space(3);
      border-radius: 50%;
      position: relative;
      background-color: $blue;
      content: '';
      visibility: visible;
      border: 2px solid $gray-7;
      transform: translate(-1px, -1px);
    }
  }

  &__graph {
    padding: space(2) 4rem space(3) 4rem;
    justify-content: center;
    margin: 0;

    &-tab {
      .nav.nav-tabs {
        display: flex;
        justify-content: center;
        border-bottom: none;

        .nav-link {
          padding: space(2) 0;
        }
      }
      .tab-pane {
        display: block;
      }
      .tab-content img {
        max-width: 100%;
      }
    }

    @include media-breakpoint-down($fund-explorer-table-switch-breakpoint) {
      padding: space(3);
    }
  }

  &__filter {
    background-color: $white;

    &.sticky {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: $zindex-sticky;

      .fund-explorer-table__wrapper {
        padding: space(3);
      }
    }

    .dropdown-item {
      padding: space(3) space(5) space(3) space(3);
    }

    .dropdown-menu .dropdown-item:last-child {
      border-bottom: 1px solid $gray-2;
    }
  }

  &__wrapper {
    // padding-bottom: space(4);
    padding: space(3) 0; // check top padding
  }

  &__table-info {
    align-items: flex-end;
    padding: 0;

    @include media-breakpoint-down($fund-explorer-table-switch-breakpoint) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 1.25rem;
    }
  }

  &__table-filters {
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 0;

    @include media-breakpoint-down(md) {
      display: flex;
      flex-wrap: wrap;

      > div {
        width: 100%;
      }
    }
  }

  &__showing {
    font-size: $font-size-xs;
    margin-right: space(3);

    @include media-breakpoint-down(md) {
      margin-bottom: space(1);
    }
  }

  .dropdown {
    &__button {
      padding: space(3) space(5) space(3) space(3);
    }
  }

  &__time-wrapper,
  &__view-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: flex-end;

    @include media-breakpoint-down(md) {
      justify-content: flex-start;
      margin-bottom: space(2);
      .text-util-md {
        width: 4rem;
      }
    }
  }

  &__view-wrapper {
    @include media-breakpoint-down(lg) {
      margin-left: space(5);
    }
    @include media-breakpoint-down(md) {
      margin-bottom: space(2);
      margin-left: 0;
      order: 1;
    }
  }

  &__time-wrapper {
    @include media-breakpoint-down(md) {
      order: 2;
    }
  }

  &__download-excel {
    font-size: $font-size-xs;
    color: $blue;
  }

  &__view--dropdown {
    margin-left: space(3);
    min-width: 10.66rem;

    @include media-breakpoint-down(md) {
      flex: 1 1 auto;
    }
  }

  &__time--dropdown {
    margin-left: space(3);

    @include media-breakpoint-down(md) {
      flex: 1 1 auto;
    }

    .dropdown__button {
      min-width: 10.25rem;
    }
  }

  &__chevron-up {
    color: $green;
    margin-right: space(1);
  }

  &__chevron-up + span {
    color: $green;
  }

  &__chevron-down {
    color: $red;
    margin-right: space(1);
  }

  &__chevron-down + span {
    color: $red;
  }

  &__divider {
    background-color: $light-blue;
  }

  &__expandable-row + tr td {
    border-top: 0;
    background-color: $white;
  }

  &-tooltip {
    position: relative;

    &-content {
      color: $gray-5;
    }
  }

  table {
    text-align: center;
    font-size: $font-size-xs;

    .collapse,
    .collapsing {
      background-color: rgba($gray-1, 0.6);
    }
  }


  th:last-child {
    .sort-thead-content {
      left: calc(100% - 7.5rem);
    }
  }

  .sort-table-thead {
    display: flex;
  }

  thead {
    background-color: $gray-4;
    color: $white;

    th {
      vertical-align: middle;
      border-top: 0;
      border-bottom: 0;
      padding: 0.75rem;
      border: 0;
    }
  }

  th {
    &:first-child .table-thead {
      border-bottom: 0.1875rem solid $white;
      padding-bottom: 0.125rem;
      color: $white;

      &-icon {
        color: $white;
      }
    }
  }

  .table-thead {
    display: flex;
    border-bottom: 0.1875rem solid $gray-4;
    padding-bottom: 0.125rem;
    color: $white;
    align-items: center;

    &-icon {
      color: $gray-7;
      align-items: center;
      margin-left: 0.2rem;
    }

    .fa-sliders-v {
      font-size: $font-size-sm;
    }

    &:hover,
    &:focus {
      border-bottom: 0.1875rem solid $white;
      padding-bottom: 0.125rem;
      color: $white;
      .table-thead-icon {
        color: $white;
      }
    }
  }

  tbody {
    tr {
      &.selected-row {
        background-color: rgba($gray-1, 0.6);
      }
    }

    tr:hover {
      background-color: rgba($gray-1, 0.6);
    }

    hr {
      margin: 0 -0.75rem;
    }
  }

  td {
    vertical-align: top;
    white-space: nowrap;

    &:first-child a {
      color: $blue;
      font-weight: $font-weight-bold;
    }

    &:nth-child(2) {
      color: $blue;
    }
  }

  .cta-link {
    .fa-plus,
    .fa-minus {
      font-size: $font-size-xxxs;
      margin-left: space(1);
      margin-bottom: 0.125rem;
      color: $dark-blue;
    }
    .fa-angle-right {
      font-size: $h2-font-size;
      margin-bottom: space(1);
      color: $dark-blue;
    }
  }

  &__view-data-container .icon-minus {
    display: none;
  }

  &__data-link {
    &:hover {
      transform: none;
      text-decoration: underline;
    }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba($blue, 0.5);
    }
  }

  &__card {
    font-size: $font-size-xs;
    padding-top: space(3);
    border-top: $border-width solid $gray-7;
    margin-top: 28px;

    &:first-child {
      margin-top: 0;
    }

    &-header {
      color: $blue;
      background-color: $gray-1;
      padding: space(4) space(2);
      margin: 0;
    }

    &-ticker,
    &-name {
      padding: 0;
    }

    &-ticker,
    &-column {
      font-weight: $font-weight-bold;
    }

    &-body {
      padding-top: space(3);
    }

    &-column,
    &-data {
      padding: 0 space(2);
    }
  }
  @include media-breakpoint-down(lg) {
    .tab-content {
      overflow: auto;
    }
    td {
      white-space: nowrap;
    }
  }

  .datepicker {
    padding-top: space(3);
    padding-bottom: space(4);
    position: relative;

    span {
      color: $gray-3;
      font-size: $font-size-xs;
      font-family: $font-family-base;
      letter-spacing: 0.09rem;
      font-weight: $font-weight-semi-bold;
      vertical-align: middle;
    }

    .active-date {
      color: $gray-5;
      &.active {
        font-weight: $font-weight-normal;
      }
    }

    &-btn {
      padding: 0 space(3);
      font-family: $font-family-base;
      font-size: $font-size-xs;
      color: $blue;
      font-weight: $font-weight-semi-bold;
    }
  }

  &__view-data-container {
    .cta-link.collapsed {
      .accordion-text-hidden,
      svg {
        display: inline;
      }
      .accordion-text-shown {
        display: none;
      }
      &:after {
        content: ' ';
      }
    }
    .accordion-text-hidden,
    svg {
      display: none;
    }

    .cta-link {
      &:after {
        content: url('#{$images-path}minus.svg');
        margin-left: space(1);
      }
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .sort-table-thead {
      display: block;
    }

    .table-thead {
      display: inline-block;
      position: relative;
      .table-thead-icon {
        position: absolute;
        display: inline-block;
      }
    }
  }
}

.popover {
  border: 0;
  .arrow {
    display: none;
  }

  &-body {
    padding: 0;
    min-width: 20rem;
  }
}

.popover.show {
  margin-top: 1.25rem;
  margin-left: space(1);

  .popover-header {
    display: none;
  }
}

.daterangepicker {
  z-index: 0 !important; // stylelint-disable declaration-no-important

  &-title {
    text-align: center;
    padding: space(1) space(4);
    margin-bottom: space(1);
    color: $gray-5;
  }

  thead {
    background-color: $white;
    color: $gray-5;
  }

  .table-condensed {
    th, td {
      padding: $border-width;
      color: $gray-5;
    }

    td {
      &.today {
        outline: $border-width solid $moderate-blue;
        background-color: $white;
        color: $gray-5;
        &.end-date.in-range {
          background-color: $moderate-blue;
          color: $white;
          outline: 0;
        }
      }
    }
  }

  .daterangepicker-cancelbtn {
    position: absolute;
    background-image: url('#{$images-path}close.svg');
    top: space(1);
    right: space(2);
    background-size: cover;
    background-repeat: no-repeat;
    height: space(4);
    width: space(4);
  }
}
