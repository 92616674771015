

.Form__Status__Message.Form__Warning__Message {
    display: none !important;
}

.ValidationRequired .invalid-feedback {
    display: block;
}

.ValidationFail > input {
    border-color: #e00;
}


.subscribe-block.subscribe-block--horizontal .subscribe-block__content {
    margin-bottom: 10px;
}

.home .subscribe-block__content .row > div:nth-child(2) {
    width: 100%;
}

@media all and (min-width:992px) {
    .subscribe-block.subscribe-block--horizontal .subscribe-block__content .row div:nth-child(2) {
        width: 66.66%;
    }
}

.subscribe-block__content:after {
    background-image: url(/globalassets/home/corp/common/abstract-background-light.svg);
    background-position: 70% 50%;
    background-repeat: no-repeat;
    background-size: auto 100%;
}

.subscribe-block--vertical .dropdown__menu {
    max-height: 12rem;
}

.subscribe-block--vertical .subscribe-block__content:after { /*Fix rendering issue with some forms that are displayed vertically. */
    min-height: 15rem;
}

.Form__Element__ValidationError {
    text-transform: uppercase;
    font-size: 0.6871875rem;
    font-weight: 600;
}

.manage-subscriptions-page, .unsubscribe-page, .subscriptions-center {
    .page-header {
        display: none;
    }

    .text-center > .btn {
        margin: 0 auto;
    }

    .unsubscribe-btn + .text-util-sm {
        display: none;
    }
}

/* Viewport-wide spinner, used in subscription center pages */
#viewport-wide-spinner {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,0.6);
    z-index: 10;
}

#viewport-wide-spinner.hide {
    display: none;
}

//for above and all other spinners
@keyframes spinner-line-fade-default {
    0%, 100% {
        opacity: 0.22; /* minimum opacity */
    }

    1% {
        opacity: 1;
    }
}

/* validation error color */
.EPiServerForms .ValidationFail {
    color: #323232 !important;
}