@import '../style/common';

.code-block {
  &__pre {
    background-color: $light;
    color: $dark;
    margin: 0;
  }

  .hljs {
    &-attr {
      color: $accent;
    }

    &-string {
      color: $primary;
    }

    &-literal {
      color: $secondary;
    }
  }
}
