@import '../style/common';

.map-block {
  &__embed {
    margin-bottom: space(4);

    @include media-breakpoint-up(lg) {
      &:before {
        padding-top: percentage(9 / 21);
      }
    }
  }
}
