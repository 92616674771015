@import '../style/common';

.card-list-columns {
  $card-list-columns-switch-breakpoint: lg;
  $card-list-columns-gutter-width: 0;
  @include make-row($card-list-columns-gutter-width);

  &__column {
    @include make-col-ready($card-list-columns-gutter-width);
    @include media-breakpoint-up($card-list-columns-switch-breakpoint) {
      @include make-col(4);
      border-right: $border-style;

      &:first-child {
        border-left: $border-style;
      }
    }
  }

  &__card {
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: space(2);
      left: space(2);
      background-color: $border-color;
      height: $border-width;
    }

    &:first-child:after {
      @include media-breakpoint-up($card-list-columns-switch-breakpoint) {
        display: none;
      }
    }
  }

  &__column:first-child &__card:first-child:after {
    display: none;
  }
}
