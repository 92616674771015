
/****
    SECTION 1 -- Main menu
****/
header.site-header.sticky > nav {
    position: relative;
}

.site-header__submenu .tab-content .tab-pane .list-unstyled {
    flex-direction: column;
    max-height: 300px;
}

.site-header__mobile-menu-action-inner {
    display: block;
}

.site-header__mobile-submenu-action {
    padding:0;
}

@media (max-width: 425px) {
    .site-header__mobile-submenu-open {
        font-size: 1.75rem;
        font-weight: 700;
        line-height: 30px;
    }
}

.main-top-menu__item ul {
    list-style: none;
    padding-left: 0;
}

a.site-header__nav-link {
    cursor: pointer;
}

.site-header__submenu-link-text {
    margin-right:0;
}

/* Maving the svg icon right next to the text on mobile. **/
.site-header__mobile-submenu .site-header__mobile-submenu-icon-wrapper {
    margin-left: 10px;
    position: relative;
}

/* Hide the mega menu close button */
.site-header__submenu-close {
    display: none;
}

//when modal is open, pull this down.
body.modal-open header#sticky-site-header {
    z-index: 1000 !important;
}

/*Mega menu L2 changes*/
@media (min-width: 768px) {
    div.display-1 {
        font-size: 1.75rem;
        font-weight: 700;
    }
}

.site-header .navbar .text-util-md, .site-header .navbar .text-util-lg {
    text-transform: none;
}

/*IE10+ fixes for the nav bar */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    div.site-header__submenu-content .tab-pane ul li {
        flex: 1 1 20px;
        max-height: 20px;
    }

    /* a height is required for IE 11 */
    div.site-header__submenu-content .tab-pane ul {
        height: 320px;
    }
}


/****
    SECTION 2 -- AHP and Gateway
****/

/* FLAGS */
.gateway__container .global-nav-flag .dropdown__button, .gateway__container .global-nav-flag.dropdown__item {
    background: url("/Static/images/ahp-country-flags-sprite.png?m=20210812") no-repeat;
    padding-left: 55px;
    background-position-x: 16px;
}

.modal-backdrop {
    z-index: 1010;
}

/* global-nav flags position */
.gateway__container .global-nav-flag.country-austria .dropdown__button, .gateway__container .global-nav-flag.country-austria.dropdown__item {
    background-position: 16px -49px !important;
}

.gateway__container .global-nav-flag.country-australia .dropdown__button, .gateway__container .global-nav-flag.country-australia.dropdown__item {
    background-position: 16px 0px !important;
}

.gateway__container .global-nav-flag.country-chile .dropdown__button, .gateway__container .global-nav-flag.country-chile.dropdown__item {
    background-position: 16px -143px !important;
}

.gateway__container .global-nav-flag.country-china .dropdown__button, .gateway__container .global-nav-flag.country-china.dropdown__item {
    background-position: 16px -192px !important;
}

.gateway__container .global-nav-flag.country-canada .dropdown__button, .gateway__container .global-nav-flag.country-canada.dropdown__item {
    background-position: 16px -95px !important;
}

.gateway__container .global-nav-flag.country-denmark .dropdown__button, .gateway__container .global-nav-flag.country-denmark.dropdown__item {
    background-position: 16px -238px !important;
}

.gateway__container .global-nav-flag.country-finland .dropdown__button, .gateway__container .global-nav-flag.country-finland.dropdown__item {
    background-position: 16px -286px !important;
}

.gateway__container .global-nav-flag.country-iceland .dropdown__button, .gateway__container .global-nav-flag.country-iceland.dropdown__item {
    background-position: 16px -1364px !important;
}

.gateway__container .global-nav-flag.country-ireland .dropdown__button, .gateway__container .global-nav-flag.country-ireland.dropdown__item {
    background-position: 16px -381px !important;
}

.gateway__container .global-nav-flag.country-italy .dropdown__button, .gateway__container .global-nav-flag.country-italy.dropdown__item {
    background-position: 16px -428px !important;
}

.gateway__container .global-nav-flag.country-germany .dropdown__button, .gateway__container .global-nav-flag.country-germany.dropdown__item {
    background-position: 16px -334px !important;
}

.gateway__container .global-nav-flag.country-luxembourg .dropdown__button, .gateway__container .global-nav-flag.country-luxembourg.dropdown__item {
    background-position: 16px -478px !important;
}

.gateway__container .global-nav-flag.country-netherlands .dropdown__button, .gateway__container .global-nav-flag.country-netherlands.dropdown__item {
    background-position: 16px -525px !important;
}

.gateway__container .global-nav-flag.country-norway .dropdown__button, .gateway__container .global-nav-flag.country-norway.dropdown__item {
    background-position: 16px -573px !important;
}

.gateway__container .global-nav-flag.country-sweden .dropdown__button, .gateway__container .global-nav-flag.country-sweden.dropdown__item {
    background-position: 16px -668px !important;
}

.gateway__container .global-nav-flag.country-switzerland .dropdown__button, .gateway__container .global-nav-flag.country-switzerland.dropdown__item {
    background-position: 16px -809px !important;
}

.gateway__container .global-nav-flag.country-united-states .dropdown__button, .gateway__container .global-nav-flag.country-united-states.dropdown__item {
    background-position: 16px -764px !important;
}

.gateway__container .global-nav-flag.country-united-kingdom .dropdown__button, .gateway__container .global-nav-flag.country-united-kingdom.dropdown__item {
    background-position: 16px -716px !important;
}

.gateway__container .global-nav-flag.country-spain .dropdown__button, .gateway__container .global-nav-flag.country-spain.dropdown__item {
    background-position: 16px -620px !important;
}

.gateway__container .global-nav-flag.country-rest-of-world .dropdown__button, .gateway__container .global-nav-flag.country-rest-of-world.dropdown__item {
    background-position: 16px -915px !important;
}

.gateway__container .global-nav-flag.country-peru .dropdown__button, .gateway__container .global-nav-flag.country-peru.dropdown__item {
    background-position: 16px -966px !important;
}

.gateway__container .global-nav-flag.country-rest-of-asia .dropdown__button, .gateway__container .global-nav-flag.country-rest-of-asia.dropdown__item {
    background-position: 16px -854px !important;
}

.gateway__container .global-nav-flag.country-portugal .dropdown__button, .gateway__container .global-nav-flag.country-portugal.dropdown__item {
    background-position: 16px -1014px !important;
}

.gateway__container .global-nav-flag.country-liechtenstein .dropdown__button, .gateway__container .global-nav-flag.country-liechtenstein.dropdown__item {
    background-position: 16px -1062px !important;
}

.gateway__container .global-nav-flag.country-france .dropdown__button, .gateway__container .global-nav-flag.country-france.dropdown__item {
    background-position: 16px -1110px !important;
}

.gateway__container .global-nav-flag.country-belgium .dropdown__button, .gateway__container .global-nav-flag.country-belgium.dropdown__item {
    background-position: 16px -1157px !important;
}

.gateway__container .global-nav-flag.country-poland .dropdown__button, .gateway__container .global-nav-flag.country-poland.dropdown__item {
    background-position: 16px -1206px !important;
}

.gateway__container .global-nav-flag.country-brazil .dropdown__button, .gateway__container .global-nav-flag.country-brazil.dropdown__item {
    background-position: 16px -1268px !important;
}

.gateway__container .global-nav-flag.country-mexico .dropdown__button, .gateway__container .global-nav-flag.country-mexico.dropdown__item {
    background-position: 16px -1321px !important;
}

.gateway__container .dropdown__item:hover, .gateway__container .dropdown__item:focus {
    background-color: #17468f !important;
}

/****
    SECTION 3 - Footer Section
*****/

.site-footer__body a, .site-footer__body a:hover, .site-footer__body a:focus {
    color: #006BB4;
}

.dropdown-menu.dropdown__menu.text-util-sm.countries {
    height: 300px;
    overflow: auto;
}

#language_button #dropdownMenuButton:after {
    content: none;
}

.language-selector__wrap .dropdown-toggle:after {
    content: none;
}

.site-header__mobile-menu-action-inner {
    white-space: normal;
}

/*Adjust font size for header disclamier*/
@media screen and (min-width: 0px) and (max-width: 768px) {
    .gateway__container #disclaimer_block.disclaimer-eu {
        font-size: 0.66rem;
    }
}

body.focus-on-content {
    &.manage-subscription {
        //specifically remove the Hi John Doe message on the manage-subscriptions page
        form.EPiServerForms .Form__MainBody > .Form__Element:first-child .FormParagraphText:first-child {
            display: none;
        }
    }

    .hero {
        display: none;
    }

    .root .sticky-wrapper {
        display: none;
    }

    footer.site-footer {
        display: none;
    }
}
