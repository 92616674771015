@import '../style/common';

body {
    padding-right: 0px !important;
}

.gateway {
  &__container {
    background-color: $white;
    padding: space(5) space(6);

    @include media-breakpoint-down(lg) {
      padding: space(4);
    }
  }

  &__toggle-icon {
    color: $dark-blue;
    margin-left: space(1);
  }

  .modal-body {
    @include padding(space(2) 0);
  }

  .modal-dialog {
    margin: 0;
    width: 100%;
    max-width: 100%;

    &:after {
      content: '';
      position: absolute;
      width: $grid-gutter-width / 2;
      top: 0;
      bottom: 0;
      left: 100%;
      background-color: $white;
    }
  }

  &__action {
    margin-right: space(4);

    @include media-breakpoint-down(lg) {
      display: block;
      width: 100%;
      margin: space(4) auto;
    }
  }
}
