@import '../style/common';

.scroll-to-top {
  bottom: 3.4rem;
  padding: space(2) 0.6rem;
  position: fixed;
  right: 1.9rem;
  visibility: hidden;

  @include media-breakpoint-down(sm) {
    bottom: space(4);
    right: space(3);
  }
}

.table-sticky {
  position: absolute;
  right: 1.875rem;
  bottom: 0;
}

.sticky-fixed {
  position: fixed;
  right: 1.9rem;
  bottom: 0;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

@keyframes fadeOut {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 0; }
}
