@import '../style/common';

.timeline-block {
  $timeline-block-switch-breakpoint: lg;

  &__hero {
    background-color: $tertiary;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: $white;
    padding-top: 9.875rem;
    padding-bottom: space(5);
  }

  &__row {
    @include make-row;
  }

  &__image-col,
  &__content-col {
    @include make-col-ready;
  }

  &__image-col {
    transform: translate(0, -18%);
    margin-bottom: -10%;
    text-align: right;

    @include media-breakpoint-up($timeline-block-switch-breakpoint) {
      @include make-col(5);
      @include make-col-offset(1);
      order: 2;
    }
  }

  &__content-col {
    @include media-breakpoint-up($timeline-block-switch-breakpoint) {
      @include make-col(6);
      order: 1;
      margin-top: space(5);
    }
  }

  &__image {
    @include img-fluid;
  }
}
