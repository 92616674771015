

.subscribe-block {
  &__content {
    position: relative;
    background-color: $light;
    padding: space(4);
    overflow: hidden;
    z-index: 0;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      min-height: 37.5rem;
      background-image: url('./assets/images/abstract-background-light.svg');
      background-position: 70% 50%;
      background-repeat: no-repeat;
      background-size: auto 100%;
      z-index: -1;
    }
  }

  &--horizontal &__content:after {
    @include media-breakpoint-up(lg) {
      background-position: 100% 50%;
    }
  }

  &--vertical &__content:after {
    background-size: auto 100%;
  }

  &__content ~ &__content {
    margin-top: space(2);
  }

  &__toggle-icon {
    color: $accent;
    transform: rotate(180deg);

    .collapsed > & {
      transform: rotate(0deg);
    }
  }

  &__disclaimer {
    @include make-text-util(xs);
  }

  &--horizontal &__disclaimer {
    margin-top: space(2);
    text-align: right;
  }
}
