
/****
    SECTION 3 - Home page
****/
.no-quote .jumbotron__quote:before {
    display: none;
}

.home .compound-hero__heading.h1.display-1 {
    font-size: 2.25rem;
    font-weight: 725;
    line-height: 1.25;
}

.compound-hero__slide {
    padding: 3rem 0;
}

.home .compound-hero__carousel-explore {
    padding-left: 1.5rem;
}

body.home .slider-for .slick-slide > div {
    height: 100%;
}

.home-page-hero {
    .compound-hero__aside {
        &.mobile-block {
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    .compound-hero__carousel-item {
        border-bottom: 1px solid hsla(216,2%,59%,.27);
        margin: 0 1.5rem;
        padding: 1.5rem 0 0;
    }

    .compound-hero__carousel-main {
        border-bottom: 0;
    }
}

/** Disable rendering of other slick slides on non-mobile -- they are not needed */
@media (min-width: 992px) {
    .home-page-hero {
        .compound-hero__aside.mobile-block {
            display: none;
        }
    }
}
@media (max-width:991.98px) {
    .home-page-hero {
        .compound-hero__aside.desktop-block {
            display: none;
        }

        .compound-hero__aside.mobile-block {
            display: block;

            .compound-hero__carousel-main {
                color: white;
            }
        }
    }
}
@media (max-width:575.98px) {
    /*.home-page .slider-for .slick-slide > div {
        height: 100%
    }*/
    .home-page-hero {
        .compound-hero__bg--overlay {
            background-position: 30%;
        }

        .compound-hero__slide {
            min-height: 320px;
            display: flex;
            align-items: center;
        }

        .compound-hero__main {
            .compound-hero__cta-wrapper {
                margin-bottom: 0rem;
            }

            .compound-hero__heading {
                margin-bottom: 2rem;

                .compound-hero__eyebrow {
                    font-size: 13px;
                    line-height: 18px;
                    color: #F0F3F6;
                }

                a {
                    font-size: 24px;
                    line-height: 30px;
                    font-weight: 700;
                }
            }

            .compound-hero__cta.btn {
                /*min-width: 250px;*/
                line-height: 17px;
            }
        }

        .compound-hero__carousel {
            .text-util-sm {
                font-size: 11px;
                line-height: 15px;
            }

            &-body {
                font-size: 16px;
                line-height: 20px;
            }

            &-explore {
                line-height: 17px;
            }
        }
    }
}

//Video hero banner
.compound-hero-new {
    .compound-hero__slide {
        .compound-hero__bg {
            overflow: hidden;

            video {
                margin: auto;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
}

.link-farm__inner-container .text-util-md {
    text-transform: none;
}