@import '../style/common';

.submit-block {
  background-color: $tertiary;
  background-image: url('./assets/images/abstract-background-tertiary.svg');
  background-position: 35% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  color: $white;
  padding: space(5) space(4);

  @include media-breakpoint-up(lg) {
    padding: space(5);
  }

  &__body {
    margin: space(3) 0;
  }

  &__body a {
    @include plain-hover-focus {
      color: inherit;
    }
  }
}
