/****
    SECTION 5 - Contact Us page
    *****/

.gc-container .accordion__toggle:focus {
    text-decoration: none;
}

.gc-container .contacts-block__item p {
    margin-bottom: 7px;
}

.accordion.heading-h3 .accordion__label.h2 {
    font-size: 1.186875rem;
    line-height: 1.1315;
}


/****
    SECTION 14 - Mission & History timeline on mobile
****/

@media all and (max-width: 520px) {
    .timeline-block__image-col {
        transform: translate(0, 0);
    }
}


/****
    SECTION 15 - Apparel page hover effect
****/
.interactive-image {
    &:hover .interactive-image__image {
        background: none !important;
    }
}