/****
    SECTION 11 - Investment Professionals jumplinks
****/

.secondary-nav.sticky {
    visibility: visible;
    position: fixed;
    top: 95px;
    left: 0;
    right: 0;
    z-index: 1000;
}

.secondary-nav {
    visibility: hidden;
    height: auto;
    background-color: #001e6d;
}

.secondary-nav ul {
    display: none;
}

@media all and (min-width: 992px) {
    .secondary-nav.sticky ul {
        display: flex;
    }
}

.secondary-nav__link {
    padding: 0.5rem;
}

#sticky-waypoint.loaded:not(.sticky) {
    display: none;
}

.investment-professionals .ip-item-section {
    margin-top: -140px
}

.investment-professionals .ip-item-section {
    padding-top: 140px
}

@media (max-width:768px) {
    .investment-professionals .ip-item-section {
        margin-top: -160px
    }

    .investment-professionals .ip-item-section {
        padding-top: 160px
    }
}

/* Landscape only - on mobile (not supported by BS4 native) */
@media (orientation:landscape) and (max-width: 991px) {
    #sticky-waypoint .secondary-nav__nav-list-mobile.show .dropdown-menu.dropdown__menu.text-util-sm {
 /*       transform: translateY(52px) !important;*/
/*        z-index: 1200 !important;*/
        max-height: 50vh;
        overflow: auto;
    }
}
