@import '../style/common';

.subscribe-widget {
  $subscribe-widget-toggle-size: 4.5rem;

  position: relative;
  z-index: $zindex-fixed;
  width: $subscribe-widget-toggle-size;

  &--sticky {
    position: fixed;
    bottom: space(3);
    right: space(3);
  }


  &__toggle {
    font-size: $font-size-lg;
    border-radius: 50%;
    padding: space(2);
    width: $subscribe-widget-toggle-size;
    height: $subscribe-widget-toggle-size;
    position: relative;
  }

  &__close-icon {
    display: none;

    .show & {
      display: inline;
    }
  }

  .show &__open-icon {
    display: none;
  }

  &__menu {
    background-color: $light;
    border: 0;
    border-radius: 0;

    &.dropdown-menu {
      margin: 0 0 space(4);
      width: auto;
      max-width: 100%;
      min-width: $modal-sm;
      font-size: $font-size-base;

      &[x-placement^=top] {
        margin: 0 0 space(4);
      }

      &[x-placement^=bottom] {
        margin: space(4) 0 0;
      }

      @include media-breakpoint-up(md) {
        min-width: 600px;
      }
    }
  }

  &__menu-inner {
    padding: space(3) space(4);
  }

  &__list {
    @include list-unstyled;
    margin-bottom: 0;
  }

  &__link-icon {
    margin-right: space(1);
    vertical-align: -0.3em;
    color: $body-color;
  }

  &__arrow {
    position: absolute;
    right: $subscribe-widget-toggle-size / 3;
    width: $subscribe-widget-toggle-size / 3;
    border-right: 0.75rem solid $transparent;
    border-left: 0.75rem solid $transparent;

    [x-placement^=top] & {
      top: 100%;
      border-top: 0.5rem solid $light;
    }

    [x-placement^=bottom] & {
      bottom: 100%;
      border-bottom: 0.5rem solid $light;
    }
  }
}
