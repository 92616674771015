.sticky-premium-content-form {
    position: fixed;
    bottom: 0;
    left: 0;
    margin-bottom: 0 !important;
    width: 100%;
    z-index: 2;

    button.close-icon {
        left: 0.5rem;
        right: auto;
    }

    .h3 {
        font-size: 1.15rem;
        line-height: 1.2;

        p {
            margin-bottom: 0.5rem;

            &.sub-title {
                font-size: 1rem;
                margin-bottom: 0;
            }
        }
    }

    .premium-flex-container,
    form .Form__MainBody section > * {
        width: 100%;
    }

    form .Form__MainBody section {
        display: flex;

        > * {
            max-width: unset;
            min-width: unset;
        }

        .premium-content-button {
            @media (min-width: 768px) {
                padding-right: 0;
            }
        }
    }

    @media (min-width: 1200px) {
        .subscribe-block__content-bg-inner:after {
            background-size: cover !important;
        }
    }

    @media (max-width: 991px) {
        .flex-parent,
        form .Form__MainBody section {
            flex-wrap: wrap;
        }

        .subscribe-block__disclaimer {
            text-align: left !important;
            padding-left: 1rem;
        }

        form .Form__MainBody section > * {
            width: 50%;

            @media (max-width: 767px) {
                width: 100%;
            }
        }
    }
}

.sticky-premium-content-form.text-white {
    button.close-icon svg,
    a {
        color: #fff;
    }
}

body {
    &.modal-open .sticky-premium-content-form:not(.premium-content-modal) {
        display: none;
    }

    &.etf-fund-page .sticky-premium-content-form .subscribe-block__content .btn {
        width: 100%;
    }
}

.sticky-premium-content-form {
    display: none;

    &.show {
        display: block;
    }
}


.sticky-premium-content-form.premium-content-modal {
    //center on the screen
    position: fixed;
    top: 50%;
    bottom: initial;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 360px;
    max-width: 360px;
    margin: 0;
    padding: 0;
    border: none;
    box-shadow: none;
    background-color: transparent;
    z-index: 1100;

    @media (max-width: 360px) {
        width: 100vw;
        max-width: 100vw;
    }

    .subscribe-block__content .row div:nth-child(2) {
        width: unset;
    }

    button.close-icon {
        left: auto;
        right: .5rem;

        &:hover svg {
            stroke: #fff;
            stroke-width: 2px;
        }
    }

    &.anchored-bottom-left {
        top: initial;
        bottom: 0;
        left: 0;
        transform: translate(0, 0);

        button.close-icon {
            right: .5rem;
            left: auto;
        }
    }

    form .Form__MainBody section {
        display: block;

        > * {
            @media (max-width: 991px) {
                width: 100%
            }
        }

        &.Form__Element > div {
            padding: 0;
            width: 100%;
        }

        .premium-content-button {
            margin-bottom: 1rem;
        }
    }

    .green-button .premium-content-button button {
        background-color: #108a12;
        border-color: #108a12;
        color: #fff;
    }

    &.large-body-font {
        .premium-content-form-body {
            font-size: 1.75rem;
            line-height: 1.2;
            margin-top: 0.75rem;
            font-weight: bold;
        }
    }

    .subscribe-block__disclaimer {
        @media (max-width: 990px) {
            padding: 0;
            text-align: left !important;
        }
    }

    .Form__Status__Message.Form__Success__Message {
        padding: 0 !important;
        padding-bottom: 15px !important;
        text-align: left;
    }

    &.large-modal {
        width: 640px;
        max-width: 640px;

        @media (max-width: 640px) {
            width: 100vw;
            max-width: 100vw;
        }

        .h3 {
            font-size: 1.875rem;
            margin-bottom: 1rem;
        }

        .form-control.FormTextbox__Input {
            width: 320px;
        }

        .premium-content-button {
            display: flex;
            justify-content: center;

            button {
                width: 180px;
            }
        }

        form .Form__MainBody section {
            display: flex !important;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;

            > * {
                width: unset;
                max-width: 320px;
            }
        }
    }

    .subscribe-block__content {
        background-color: unset;

        &:after {
            background-image: unset
        }
    }

    .premium-content-heading {
        font-size: .75rem;
        font-weight: 600;
        letter-spacing: normal;
        line-height: 1;
        padding-bottom: 10px;
        text-transform: uppercase;
    }
}

.modal-backdrop.sticky-premium-form-backdrop {
    opacity: 0.8
}

body.modal-open {
    .modal-backdrop.sticky-premium-form-backdrop {
        display: none;
    }

    .sticky-premium-content-form.premium-content-modal {
        z-index: 5;
    }
}

.gated-content-block {
    display: none;

    &.unlocked {
        display: block;
    }
}