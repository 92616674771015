﻿/****
   VOICES PAGE
*/
.voices-page {
    .related-voices .insight-card__image {
        background-position: top;
    }

    .insights-bio__image {
        background-position: top center;
        min-height: 300px;
    }

    .insights-bio__social-icon {
        vertical-align: -0.3em;
        font-size: 1.75rem;
        margin-left: 0;
    }

    .insight-card__eyebrow {
        margin: 0 1rem 1rem 0;
    }

    .related-voices .carousel-block__main--mobile .insight-card {
        padding: 1.5rem 1.5rem;
        border: 1px solid #e6e7e8 !important;
        margin-bottom: 1.5rem !important;
    }

    .mobile-dropdown {
        display: none;
    }

    .person-title {
        font-family: "Open Sans Lite", "Helvetica", sans-serif;
    }
}

@media all and (max-width: 990px) {
    .filter-wrapper {
        margin-top: 20px;
    }

    .voices-page {
        .desktop-dropdown {
            display: none;
        }

        .mobile-dropdown {
            display: block;
        }
    }
}

@media all and (max-width: 426px) {
    .voices-page {
        .insights-bio__social-link {
            padding-top: 5px;
        }

        .insight-card {
            padding: 1.5rem 0rem;
        }
    }
}