﻿

/* Section header's of components need to be bold across the board. */
.section-header h2.text-util-md {
    font-weight: bold;
}

.section-header:nth-of-type(2) {
    border-bottom: 1px solid #e6e7e8;
}

.video-insights-video-player .BrightcoveExperience.brightcove-video-player {
    position: absolute;
    top: 0;
}


.insights-webinar-registration-form {

    .event-registration__content {
        background-color: unset;
        background-image: none;
        padding: 0px;
    }

    .speaker-question > label {
        color: #0AB2BF;
    }

    .FormParagraphText.required-label {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .top-SelfDeclaredClient {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .top-SelfDeclaredClient .FormChoice__Input--Radio {
        line-height: 10px;
    }

    .custom-select-form .FormStep .multiselect-label {
        flex: 0 0 60%;
    }

    .custom-select-form .FormStep .multiselect-items {
        flex: 0 0 30%;
        line-height: 14px;
        margin-top: 8px;
        display: flex;
    }

    .top-SelfDeclaredClient .FormChoice__Label {
        margin-bottom: 0;
    }

    .SelfDeclaredFundsOwned-parent > label {
        display: none;
    }

    .SelfDeclaredFundsOwned {
        margin-top: 3px;
    }

    .top-WebinarDocumentsRequested label {
        &.custom-control-label {
            display: inline-block;
            font-size: .75rem;
            font-weight: 600;
            letter-spacing: .09375rem;
            margin-bottom: .5rem;
            text-transform: uppercase;
        }
    }

    @media (min-width: 992px) {
        .FormSubmitButton {
            margin-top: 0 !important;
        }

        .top-WebinarDocumentsRequested {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }

        .top-WebinarDocumentsRequested > .custom-checkbox.custom-control {
            max-height: 45px;
        }

        .subscriptionContent-parent span.label {
            margin-top: 8px;
        }
    }
}

/*Styles for the recommended Block - WEBP-4570*/
.recommended-insight-wrapper {


    .featured-block__recommended-wrapper {
        /*flex-direction: column;*/

        .grid-card {
            &__eyebrow .text-util-md {
                /*font-size: 11px;
                line-height: 15px;
                letter-spacing: 1px;*/
            }

            &__header {
                margin-bottom: 10px;

                h3 {
                    /*font-size: 19px;
                    line-height: 25px;*/
                }
            }
            /** Hide all dividers on desktop only */
            @media (min-width: 768.2px) {
                &__divider {
                    display: none;
                }
            }
        }

        .date-text {
            /*font-size: 11px;
            line-height: 15px;*/
        }
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        .featured-block .grid-card__divider {
            height: 1px;
        }
    }

    @media (max-width: 767.98px) {
        .section-header {
            margin-bottom: 0px;
        }
    }
}

/*Styles for the insights landing page section headings*/
.text-util-md-title {
    font-weight: 700;
    color: #323232;
    letter-spacing: 2.46px;
    line-height: 18px;
    font-size: 16px;
    text-transform: uppercase;
}

/*Styles for the trending block - WEBP-4570*/
.trending-content-wrapper {

    @media (min-width: 768px) and (max-width: 991.98px) {
        .grid-card__divider {
            height: 1px;
        }
    }
    /* Desktop only -- hide the divider elements on the last two items of the trending content*/
    @media (min-width: 768.2px) {
        .trending-content-wrp {
            height: 100%;
        }

        .featured-block__grid-card .row > div.col-lg-6:nth-last-child(-n+2) .grid-card__divider {
            display: none;
        }
    }

    @media (max-width: 767.9px) {
        .links-hero__dropdown-list {
            //margin-top: 1rem;

            .section-header {
                margin: 0rem -1rem;
            }

            .dropdown__button {
                position: relative;
                border-bottom: 1px solid #e6e7e8;
            }

            .dropdown__button-condensed + .dropdown-menu {
                top: 1.5rem !important;
            }
        }
    }

    .links-hero {
        &__links-title {
            display: inline-block;
            font-weight: 700;
            letter-spacing: .09375rem;
        }

        &__insights-list {
            margin-top: 7px !important;
            margin-bottom: 7px !important;
        }

        &__links {
            border-right: 1px solid #979797;
            display: inline-block;
            font-weight: 600;
            letter-spacing: .09375rem;
            padding: 0rem 1.5rem;
            line-height: 17px;

            &:last-child {
                border-right: none;
            }
        }
    }

    .trending-content-wrp {
        .grid-card {
            &__eyebrow {
                //padding-top: 10px;
            }

            &__header {
                margin-bottom: 0rem;
            }

            &__body {
                margin-bottom: 0px;
                margin-top: 10px;

                p {
                    display: -webkit-box;
                    overflow: hidden;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }

            &__date-time {
                font-weight: 700;
                margin-top: 10px;
            }

            &__tags {
                line-height: 1;
                margin-top: 10px;

                .tag-title {
                    font-weight: 700;
                }

                .tag-item {
                    border-right: 1px solid #979797;
                    padding: 0px 8px;

                    &:last-child {
                        border-right: none;
                    }
                }
            }

            @media (min-width: 768px) and (max-width: 991.98px) {
                &__divider {
                    height: 1px;
                }
            }
        }
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        .featured-block__editors {
            border-bottom: 0px;
        }
    }
.insights-webinar-registration-form .event-registration__content{
    background-color:unset;
    background-image:none;
    padding:0px;
    }
}


@media (min-width: 992px) {
    .insights-webinar-registration-form .custom-select-form .FormStep {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        & * {
            flex: 0 1 48%
        }
    }
}
@media (min-width: 768px){
    .insights-grid .section-header {
        &__dropdown {
            /*min-width: 250px;*/
            width: auto;

            .dropdown-item {
                &.disabled, &:disabled {
                    color: rgba(108 117 125 / 50%);
                }
            }

            &:nth-child(2) {
                margin-left: 60px;

                .dropdown__button-condensed + .dropdown-menu {
                    min-width: 250px;
                }
            }
        }
    }
}
@media (min-width: 768px) and (max-width: 991.98px) {
    .links-hero__dropdown-list .dropdown__menu {
        /*left: -64px !important;*/
        /*width: 200px !important;*/
    }
    .links-hero__dropdown-list #bundle-links {
        min-width: 200px !important;
        text-align:right;
    }
}

@media (max-width: 767.9px){
    .insights-grid.ve-dynamic-related-media .section-header {
        margin: 0rem -1rem;
    }
    .trending-content-wrapper .trending-content-wrp .grid-card__body p{
        margin-bottom:0px;
    }
    .trending-content-wrapper .trending-content-wrp .grid-card__eyebrow {
        padding-top: 10px;
    }
}


@media (max-width:639.9px){
    .insights-grid .section-header__dropdown:nth-child(2){
        margin-left:30px;
    }
}

/*WEBP-4700 - dropdown fix for less than 400px devices*/
@media (max-width:400px) {
    #InsightsDropdown .dropdown__button {
        max-width: 200px;
    }
}
.page-class-insights.webinars .webinar-page-left-content-wrp .grid-card .grid-card__author {
    display:none;
}

.thought-leaders-card__image-container .image{
    background-position-y:top;
}

.register-form__modal-body.modal-body .thank-you {
    padding: 0 !important;
}
.slick-track {
    margin-left: 0px;
}
/*** 
    For the blog pages -- these are older styles for backwards compatibility of older markup
*/

body.page-class-insights.blogs {
    .blog-post.content {
        .tbl-data {
            padding: 15px;
            margin-right: 10px;
            margin-bottom: 10px;
            width: 100%;

            .no-border-top {
                border-top: none !important;
            }

            .tbl-data.monthly-asset {
                width: 100%;
            }

            td, .tbl-header, span.tbl-disc {
                color: #6e6f72;
                font-size: 12px;
                font-weight: bold;
            }

            .tbl-header {
                font-weight: bold;
                border-top: 2px solid #b2b3b2;
                padding: 10px 5px 5px 5px;
            }

            .tbl-header-border {
                border-top: 2px solid #b2b3b2;
                padding: 5px;
                line-height: 14px;

                .data {
                    color: #6e6f72;
                    width: auto;
                    text-align: right;
                    padding-right: 15px;
                }
            }

            .data-td {
                border-top: 1px solid #b2b3b2;
                padding: 5px;
                line-height: 14px;

                &.data {
                    color: #6e6f72;
                    width: auto;
                    text-align: center;
                    padding-right: 15px;
                }
            }

            .col1 {
                font-weight: bold;
            }

            .last {
                border-bottom: 1px solid #b2b3b2;
            }

            .shade {
                background: #a0dcf2;
            }

            .row-green {
                background: palegreen;
            }

            .row-pink {
                background: lightpink;
            }

            .align-right {
                text-align: right;
                padding-right: 10px;
            }

            td.data-td.col1.noborder {
                border-top: 0;
            }

            .ra-segments {
                margin-bottom: 5px;

                th {
                    &.col1 {
                        width: 22%;
                    }

                    &.col2 {
                        width: 20%;
                        text-align: center;
                    }
                }

                td {
                    vertical-align: top;
                    text-align: left;
                    padding: 5px;
                    line-height: 18px;
                    font-size: 12px;
                    border-top: 1px solid #b2b3b2;

                    &.col2 {
                        text-align: center;
                    }

                    &.col3 {
                        vertical-align: top;
                        line-height: 19px;
                    }
                }
            }

            .pale-blue {
                background-color: #D9E2F3;
            }

            padding: 15px;
            margin-right: 10px;
            margin-bottom: 10px;
            width: 100%;

            td, .tbl-header, span.tbl-disc {
                color: #6e6f72;
                font-size: 12px;
            }

            .tbl-header {
                font-weight: bold;
                border-top: 2px solid #b2b3b2;
                padding: 10px 5px 5px 5px;
            }

            .data-td {
                border-top: 1px solid #b2b3b2;
                padding: 5px;
                line-height: 14px;

                &.data {
                    color: #6e6f72;
                    text-align: center;
                    padding-right: 15px;
                    font-weight: normal;
                    text-decoration: none;
                }
            }

            .align-right {
                text-align: right;
                padding-right: 10px;
            }

            &.data-list {
                width: 100%;
            }

            .data-list {
                ul li {
                    margin-left: 25px;
                    list-style-type: disc;
                }

                td {
                    border: 1px solid #b2b3b2;
                    padding: 5px;
                }
            }

            .data-head {
                color: #6e6f72;
                text-align: center;
            }

            .black strong {
                color: #000;
            }

            .pale-blue {
                background-color: #D9E2F3;
            }

            ul.content-list li {
                margin-bottom: 0px;
            }

            .td2 {
                text-align: center !important;
                border: 1px solid #b2b3b2;
                font-size: 12px;
                width: 18%;
            }

            .data-td.align-left {
                text-align: left !important;
            }

            td.neg {
                background-color: #dbefea;
            }
        }

        img {
            width: 100%;
        }

        .tbl-data .text-left {
            text-align: left !important;
        }

        h3 {
            margin-top: 30px;
        }
    }
}


.byline__list-title{
    min-width:9.7rem;
    width:auto;
}

.insights-accordion-block .insights-accordian-block__link {
    color: inherit !important;
}

.breadcrumb .dropdown__button-condensed + .dropdown-menu{
    top: 42px !important;
    transform:none !important;
}

.insights-grid .dropdown__button-condensed + .dropdown-menu{
    top:40px !important;
}

//Insights-type dropdown styles for capability pages with categories WEBP-4605
.format-dropdown {
    .dropdown__button {
        padding-right: 23px;
    }

    .dropdown__button-condensed + .dropdown-menu {
        min-width: 250px;
    }

    .dropdown__label {
        /*padding-right: 30px;*/
    }

    @media (max-width: 767.98px) {
        &.section-header__dropdown {
            width: auto;
        }

        .dropdown__label {
            padding-right: 0px;
        }
    }
}

.insights-card__body {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
}

div.grid-card__label-link:hover {
    color: #323232;
}
.secondary-nav.sticky {
    z-index: 990;
}
.utility-menu__icon{
    width:5rem;
    z-index:100;
}
/*Make the eyebrow bold*/
.grid-card__eyebrow .text-util-md, .insight-card__eyebrow .insight-card__topic.text-util-sm, .event-block__topic.text-util-lg {
    font-weight: bold !important;
}

.fund-card .fund-card__ticker{
    font-weight:700;
}

.grid-card__body p{
    margin-bottom:0px !important;
}


/**
    Chart of the month -- grid card
*/
.chart-of-the-week .grid-card__body {
    margin-top: 0;
}

.share-with-a-friend-form__body {
    .row.grouped-wrapper {
        width: 100%;
    }

    .Grouping {
        width: 100%;
    }

    .FormSubmitButton {
        margin-left: 15px !important;
    }

    .Form__Element {
        margin-bottom: 1.0rem !important;
    }
}

.share-with-a-friend-form {
    &__right-block {

        @include media-breakpoint-down(sm) {
            padding: 0 15px;
        }
    }
}

/*WEBP-4776 - Request from Donna to remove right padding on the callout card*/
.callout__content{
    padding-right: unset;
}
@media (max-width: 991.98px){
    .blog-summary-wrapper .callout__content-item {
        margin-bottom: unset;
    }
}

body.page-class-insights.blogs {
    /*To hide mobile and desktop images on faq pages for the moment */
    .desktop-image {
        display: block;
    }

    .mobile-image {
        display: none;
    }

    @media screen and (max-width: 425px) {
        .desktop-image {
            display: none;
        }

        .mobile-image {
            display: block;
        }
    }
    /* Table styles from older pages to maintain backward compatibility */
    .blog-post.content .tbl-data .tbl-header, body.page-class-insights.blogs .blog-post.content .tbl-data span.tbl-disc, body.page-class-insights.blogs .blog-post.content .tbl-data td {
        color: inherit !important;
        font-size: 0.8rem;
    }
    /* Chart disclosure -- Making it italics like in the older blogs. */
    .blog-post.content .chart-disclosure {
        font-size: 10px;
        font-style: italic;
        line-height: 14px;
    }
    /* End Table styles from Chaithika */
}

//Billboard block
.billboard-block__card-link, .billboard-block__card-link {
    &:hover, &:focus {
        .billboard-block__title {
            text-decoration: none;
        }
    }
}

/* Custom-page Jumbotron block */
.custom-page-content {
    .jumbotron {

        @media (min-width: 992px) {
            padding: 3rem 6rem;
            margin-right: 15px;
            margin-left: 15px;
            width: calc(100% - 30px);
        }

        .jumbotron__cta {
            svg {
                width: 38px;
                height: 22px;
                padding-left: 10px;
                line-height: 1em;
                font-size: 1.8rem;

                path {
                    fill: #ffffff;
                }
            }

            margin: 0;

            .cta-link__text {
                font-size: 1.3rem;
                line-height: 1.25;
                text-transform: none;
                letter-spacing: inherit;
                display: inline;
                margin-bottom: 0px;
            }

            @media (min-width: 768px) {
                .cta-link__text {
                    font-size: 1.88rem;
                    font-weight: 600;
                    letter-spacing: -0.55px;
                    line-height: 1.4em;
                    margin-bottom: 0;
                }

                svg {
                    line-height: 1.1315em;
                    font-size: 2rem;
                    height: 40px;
                }
            }

            @media (min-width: 425px) {
                .cta-link__text {
                    display: inline;
                    float: left;
                }

                svg {
                    display: inline;
                    float: left;
                }
            }

            @media all and (max-width: 450px) {
                .cta-link__text {
                    font-size: 1.2rem;
                }

                svg {
                    width: 25px;
                    height: 25px;
                }
            }

            @media all and (max-width: 400px) {
                .cta-link__text {
                    font-size: 1.4rem;
                }

                svg {
                    margin-top: -4px;
                }
            }
        }

        .jumbotron__quote {
            display: none;
        }

        .jumbotron__lead {
            display: none;
        }

        .jumbotron__body {
            display: none;
        }
    }
}

/* Content for Schedule a call block */

.carousel-block__main.carousel-block__main--desktop .slick-slide > div {
    height: 100%;
    margin-right: 1.5rem;
}

.related-insights .carousel-block__main.carousel-block__main--desktop .slick-slide > div,
.related-insights .carousel-block__main.carousel-block__main--desktop .slick-slide > div > div {
    height: 100%;
    margin-right: 0rem;
}

.carousel-block__main.carousel-block__main--desktop .slick-slide > div .insight-card.carousel-block__item {
    height: 100%;
}

/* end */

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .callout-text .rich-text-block.rich-text-block--insight-content {
        width: 92%;
    }

    .latest-featured-block__featured-secondary-top-section .grid-card, .featured-block__fundcard-data .grid-card {
        flex: 1;
    }

    .billboard-block__image-icon {
        margin-right: 30px;
    }

    .grid-card {
        display: block !important;
        position: relative;

        &__divider {
            position: absolute;
            bottom: 10px;
        }
    }

    .bundled-block .grid-card__divider {
        bottom: -10px !important;
    }
    .trending-content-wrapper .links-hero__insights-list{
        margin-bottom:1rem !important;
    }
}

iframe.iFrameProspectus {
    width: 100%;
    min-height: 410px;
}

/*Styles on the thought leader person block*/
.bio.row{
    border-bottom:0px;
}
.thought-leader-person {
    .bio {
        margin-bottom:0px;

        &__image-desktop {
            max-height: 100%;
            max-width: 100%;
            width: 100%;
        }

        &__content-wrapper {
            padding-left: 30px;
        }

        &__sub-heading {
            display: block;
            font-size: 1.3rem;
            line-height: 1.35;
        }

        @include media-breakpoint-down(md) {
            &__content-wrapper {
                padding-left: 0px;
            }

            &__sub-heading {
                font-size: 1.2rem;
            }
        }
    }
}

.evergreen-content-block {
    .grid-card__body {
        margin: 0px 0px;
        font-size: 18px;
    }

    .sub-item-half .grid-card__body {
        font-size: 16px;
        margin-bottom: 15px;
    }

    .sub-item-full .grid-card__body {
        margin-bottom: 15px;
    }

    .grid-card.bundled-block__inflation .grid-card__divider {
        display: none;
    }

    .mobile-header {
        border-bottom: 1px solid #939598;
        width: 100%;
        margin: 0rem 0rem;
    }

    @include media-breakpoint-down(md) {
        .evergreen-sub-items-wrp .small-card {
            margin-top: 15px;

            .grid-card__header h3 {
                font-size: 1.25rem;
            }

            .grid-card__body {
                font-size: 18px;
            }
        }

        .evergreen-show-more-link {
            position: relative;
            padding: 10px 0px;
            text-decoration: none !important;

            &[aria-expanded="true"] {
                .dropdown__label-hidden {
                    display: none;
                }

                .dropdown__label-shown {
                    display: inline;
                }

                svg {
                    transform: rotate(180deg);
                }
            }

            &[aria-expanded="false"] {
                .dropdown__label-hidden {
                    display: inline;
                }

                .dropdown__label-shown {
                    display: none;
                }
            }
        }

        .divider-bottom {
            order: 1;
        }

        .grid-card__body {
            margin-bottom: 15px;
            font-size: 18px;
        }

        .grid-card.bundled-block__inflation .grid-card__divider {
            display: block;
        }

        .desktop-header {
            display: none !important;
        }
    }

    @include media-breakpoint-up(md) {
        .show-desktop {
            display: block !important;
        }

        .evergreen-show-more-link {
            display: none;
        }
    }
}

