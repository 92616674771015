
/*Share block on news & insights mobile*/
@media all and (max-width:992px) {
    .news-and-insights .share.dropdown .share__menu {
        left: auto !important;
        right: 0 !important;
    }
}


/****
    SECTION 12 - Blog overview pages
****/
.dropdown-menu a:hover {
    text-decoration: none;
}


/***
    Video page
*/

.video-no-iframe .embed-responsive::before {
    content: none;
}

.video-no-iframe video.vjs-tech {
    position: relative;
}

.news-and-insights.podcasts .mb-5 > h2 {
    display: none;
}


/****
    SECTION 15 - Video page carousel
****/
.noCarouselInit .slick-track {
    margin: 0;
}

/****
    SECTION 11 - Video preview list
****/

.video-playlist__container .image.video-playlist__preview-image:before {
    content: "";
    background: rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
}

.video-playlist__container .video-playlist__preview-title {
    z-index: 2;
}


/***
    Tables used in blogs.
*/

table {
    max-width: 100%;
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;
}

.tbl-data {
    padding: 15px;
    margin-right: 10px;
    margin-bottom: 10px;
    width: 100%;
}

.tbl-data .tbl-header {
    font-weight: bold;
    border-top: 2px solid #b2b3b2;
    padding: 10px 5px 5px 5px;
    color: #6e6f72;
    font-size: 12px;
}

.tbl-data .data-td.data {
    color: #6e6f72;
    text-align: center;
    padding-right: 15px;
    font-weight: normal;
    text-decoration: none;
    width: auto;
}

.tbl-data .data-td {
    border-top: 1px solid #b2b3b2;
    padding: 5px;
    line-height: 14px;
}

.tbl-data .last {
    border-bottom: 1px solid #b2b3b2;
}

.blog-post.content .tbl-data td {
    color: #6e6f72;
    font-size: 12px;
}

.chart-disclosure {
    font-size: 10px;
    font-style: italic;
    line-height: 14px;
}


.blogs .text-util-sm h1 {
    font-size: 0.6871875rem;
    font-weight: 600;
    letter-spacing: 0.0625rem;
    text-transform: uppercase;
    display: inline;
}

/**
    Pagination styles
*/
@media screen and (max-width: 640px) {
    .page-class-insights .paginator, .news-and-insights .paginator {
        display: flex;
        justify-content: center;

        li.paginator__list-item.Pagination-ellip-wrp {
            display: flex;
            align-items: center;
        }
    }
}